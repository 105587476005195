import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-tipo-tramite',
  templateUrl: './tipo-tramite.component.html',
  styleUrls: ['./tipo-tramite.component.css']
})
export class TipoTramiteComponent implements OnInit {
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];


  data:any = {};
  colorScheme = {
    domain: ['#0D47A1', '#B71C1C', '#42A5F5', '#FDD835', '#8D6E63', '#4DD0E1']
  };
  constructor(
    private restApi:RestApiProvider
  ) { 
    
    this.cargar_tipo_de_pago();
    this.cargar_proveedor();
    
    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];

    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

  }

  cargar_tipo_de_pago(){
    this.restApi.cargar_tipo_de_pago(this.subgrupo, this.parentesco).then(response =>{
      this.data['tipo_pago'] = response['tipo_pago'];
    },error => {
      this.loading = false;
    }).catch(err=> {
      this.loading = false;
    });
  }
  cargar_proveedor(){
    this.restApi.cargar_proveedor(this.subgrupo, this.parentesco).then(response =>{
      this.data['proveedor'] = response['proveedor'];
      this.loading = false;
    },error => {
      this.loading = false;
    }).catch(err=> {
      this.loading = false;
    });

  }

  updateGraph(){
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_tipo_de_pago();
    this.cargar_proveedor();
  }

  ngOnInit(): void {
  }

  onSelect(event){

  }
  onActivate(event){

  }
  onDeactivate(event){

  }

}
