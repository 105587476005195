import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { IndicadoresPoblacionalesComponent } from './indicadores-poblacionales/indicadores-poblacionales.component';
import { IndicadoresCrecimientoComponent } from './indicadores-crecimiento/indicadores-crecimiento.component';
import { IndicadoresEdadComponent } from './indicadores-edad/indicadores-edad.component';
import { MomentosVidaComponent } from './momentos-vida/momentos-vida.component';
import { IndicadoresSiniestralidadComponent } from './indicadores-siniestralidad/indicadores-siniestralidad.component';
import { SeveridadComponent } from './severidad/severidad.component';
import { CedulaComponent } from './cedula/cedula.component';
import { GastoComponent } from './gasto/gasto.component';
import { BiometricsComponent } from './biometrics/biometrics.component';
import { FrecuenciaComponent } from './frecuencia/frecuencia.component';
import { InicioPadecimientoComponent } from './inicio-padecimiento/inicio-padecimiento.component';
import { TipoTramiteComponent } from './tipo-tramite/tipo-tramite.component';
import { HospitalComponent } from './hospital/hospital.component';
import { MedicoComponent } from './medico/medico.component';
import { SiniestralidadPorEstadoComponent } from './siniestralidad-por-estado/siniestralidad-por-estado.component';
import { DetaleAltasBajasComponent } from './detale-altas-bajas/detale-altas-bajas.component';
import { AnalisisProveedorComponent } from './analisis-proveedor/analisis-proveedor.component';
import { CargaArchivosComponent } from './carga-archivos/carga-archivos.component';
import { AltasBajasComponent } from './altas-bajas/altas-bajas.component';
import { AdminComponent } from './admin/admin.component';


const routes: Routes = [
  { 
    path:'', 
    component:PagesComponent,
    children:[
      { path:'dashboard', component:DashboardComponent },
      { path:'indicadores-poblacionales', component:IndicadoresPoblacionalesComponent },
      { path:'indicadores-crecimiento', component:IndicadoresCrecimientoComponent },
      { path:'indicadores-edad', component:IndicadoresEdadComponent },
      { path:'momentos-vida', component:MomentosVidaComponent },
      { path:'principales-indicadores-siniestralidad', component:IndicadoresSiniestralidadComponent },
      { path:'severidad', component:SeveridadComponent },
      { path:'cedula', component:CedulaComponent },
      { path:'gasto', component:GastoComponent },
      { path:'biometrics', component:BiometricsComponent },
      { path:'frecuencia', component:FrecuenciaComponent },
      { path:'inicio-padecimiento', component:InicioPadecimientoComponent },
      { path:'tipo-tramite', component:TipoTramiteComponent },
      { path:'hospital', component:HospitalComponent },
      { path:'medico', component:MedicoComponent },
      { path:'siniestralidad-estado', component:SiniestralidadPorEstadoComponent },
      { path:'detalle-altas-bajas', component:DetaleAltasBajasComponent },
      { path:'analisis-proveedor', component:AnalisisProveedorComponent },
      { path:'carga-archivos', component:CargaArchivosComponent },
      { path:'altas-bajas', component:AltasBajasComponent },
      { path:'admin', component:AdminComponent},
      
      { path:'usuarios', component:UsuariosComponent },
      { path:'', redirectTo:'/dashboard', pathMatch:'full'},
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
