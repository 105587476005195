import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-momentos-vida',
  templateUrl: './momentos-vida.component.html',
  styleUrls: ['./momentos-vida.component.css']
})
export class MomentosVidaComponent implements OnInit {
  data:any = {};
  distribucion_por_edad_hijos:any = {};

  view: any[] = [400, 200];


  loading = true;

  dropdownListGenero = [];
  dropdownListSubgrupo = [];
  selectedItemsGenero = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  genero = [];
  subgrupo = [];



  constructor(
    private restApi: RestApiProvider,
    private toastr:ToastrService
  ) { 
    
    this.cargar_momentos_vida_edad();
    this.cargar_momentos_vida_dist_edad();
    this.cargar_momentos_vida_edo_civil();
    this.cargar_grafica_distribucion_titulares();

    this.restApi.cargar_generos_siniestralidad().then(response =>{
      this.dropdownListGenero = response['genero'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
  }
  
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#103B87','#056AB0', '#6DC8BF', '#81DCE4']
  };

  cargar_momentos_vida_edad(){
    this.restApi.cargar_momentos_vida_edad(this.subgrupo, this.genero).then(response =>{
      this.data['edad_titulares'] = response['edad_titulares'];
      this.data['grafica_titulares'] = response['grafica_titulares'];
    }, error => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    }).catch(err => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    });
    
  }
  cargar_momentos_vida_dist_edad(){
    this.restApi.cargar_momentos_vida_dist_edad(this.subgrupo, this.genero).then(response =>{
      this.distribucion_por_edad_hijos = response['distribucion_por_edad_hijos'];
    }, error => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    }).catch(err => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    });
    
  }
  cargar_momentos_vida_edo_civil(){
    this.restApi.cargar_momentos_vida_edo_civil(this.subgrupo, this.genero).then(response =>{
      this.data['estado_civil_titulares'] = response['estado_civil_titulares'];
    }, error => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    }).catch(err => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    });
  }
  
  cargar_grafica_distribucion_titulares(){
    this.restApi.cargar_grafica_distribucion_titulares(this.subgrupo, this.genero).then(response =>{
      this.data['grafica_distribucion_titulares'] = response['grafica_distribucion_titulares'];
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    }).catch(err => {
      this.loading = false;
      this.toastr.error('Ha ocurrido un error al cargar la gráfica');
    });
  }

  updateGraph(){
    this.genero = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsGenero){
      this.genero = this.selectedItemsGenero.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_momentos_vida_edad();
    this.cargar_momentos_vida_dist_edad();
    this.cargar_momentos_vida_edo_civil();
    this.cargar_grafica_distribucion_titulares();
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  ngOnInit(): void {
  }

}
