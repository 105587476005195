import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import { RestApiProvider } from 'src/app/services/request.services';

import mxMap from '@highcharts/map-collection/countries/mx/mx-all.geo.json'
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-siniestralidad-por-estado',
  templateUrl: './siniestralidad-por-estado.component.html',
  styleUrls: ['./siniestralidad-por-estado.component.css']
})
export class SiniestralidadPorEstadoComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions:Highcharts.Options
  chartConstructor = "mapChart";
  data: any = {}


  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];


  
  constructor(
    private restApi: RestApiProvider
    ) {

      
      this.cargar_siniestros_por_estado();

      this.restApi.cargar_parentescos_siniestralidad().then(response =>{
        this.dropdownListParentesco = response['parentescos'];
      }); 
  
      this.restApi.cargar_grupos().then(response =>{
        this.dropdownListSubgrupo = response['grupos'];
      }); 
  
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Seleccionar todo',
        unSelectAllText: 'Deseleccionar todo',
        itemsShowLimit: 7,
        allowSearchFilter: false
      };


   }

   cargar_siniestros_por_estado(){
    this.restApi.cargar_siniestros_por_estado(this.subgrupo, this.parentesco).then(response =>{
      this.data = response;
      
      this.chartOptions = {
        chart: {
          map: mxMap,
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          min: 0
        },
        series: [{
            type:"map",
            joinBy: 'hc-key',
            data: this.data['siniestralidad_por_estado'],
            visible:true,
            name: '',
            states: {
                hover: {
                    color: '#BADA55'
                }
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}'
            }
        }]
      }
      this.loading = false;
    });
   }


   updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_siniestros_por_estado();
  }


  ngOnInit(): void {
  }

}
