import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-analisis-proveedor',
  templateUrl: './analisis-proveedor.component.html',
  styleUrls: ['./analisis-proveedor.component.css']
})
export class AnalisisProveedorComponent implements OnInit {
  data:any = {};
  padecimento_seleccionado:string = '';
  constructor(
    private restApi:RestApiProvider,
    private routeParams:ActivatedRoute
  ) { 
    this.routeParams.queryParams.subscribe(params => {
      this.restApi.cargar_lista_padecimientos().then(response =>{
        this.data['padecimientos'] = response['padecimientos'];

        if (params && params['padecimiento']){
          this.padecimento_seleccionado = params['padecimiento'];
        } else{
          this.padecimento_seleccionado = this.data['padecimientos'][0]
        }
        this.changePad(this.padecimento_seleccionado)
      });
    });
  }

  changePad(pad:string){
    this.restApi.cargar_conteo_siniestros(pad).then(response =>{
      this.data['padecimientos_atendidos'] = response['padecimientos_atendidos'];
    });
  }

  ngOnInit(): void {

  }

}
