import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/request.services';
import * as Highcharts from 'highcharts';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-severidad',
  templateUrl: './severidad.component.html',
  styleUrls: ['./severidad.component.css']
})
export class SeveridadComponent implements OnInit {
  data:any = {}
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions:Highcharts.Options

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Porcentaje';
 

  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];



  colorScheme = {
    domain: ['#81DCE4']
  };
  
  colorScheme2 = {
    domain: ['#B72467']
  };

  constructor(
    private restApi:RestApiProvider
  ) {
    this.cargar_frecuecia_padecimientos();
    this.cargar_severidad();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
   }
  
  cargar_frecuecia_padecimientos(){
    this.restApi.cargar_serveridad_padecimientos(this.subgrupo, this.parentesco).then(response => {
      this.data['padecimientos'] = response['padecimientos'];
    },serror => {this.loading=false;}).catch(err=>{this.loading=false;});
  }

  cargar_severidad(){
    this.restApi.cargar_severidad(this.subgrupo, this.parentesco).then(response =>{
      this.data = response;
      this.chartOptions = {
        title: {
          text: ''
        },
        tooltip: {
          formatter: function() {
            return '<b>' + '</b>' + this.y.toFixed(2) + '%';
          }
        },
        xAxis: {
            categories: this.data['categoria_acumulada'],
    
        },
        yAxis: {
            labels: {
                format: '%{text}' // The $ is literally a dollar unit
            },
            title: {
                text: 'Porcentaje'
            }
        },
        series: [{
          type: 'line',
          name: 'Porcentaje acumulado',
          data: this.data['distribucion_acumulada_linea']
        },
        {
          type: 'column',
          name: 'Porcentaje',
          data: this.data['distribucion_acumulada_barra']
        }]
      }

      this.loading=false;
  

    },serror => {this.loading=false;}).catch(err=>{this.loading=false;});
  }

  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_frecuecia_padecimientos();
    this.cargar_severidad();
  }


  ngOnInit(): void {
  }

}
