import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-gasto',
  templateUrl: './gasto.component.html',
  styleUrls: ['./gasto.component.css']
})
export class GastoComponent implements OnInit {
  data:any = {};

  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];

  constructor(
    private restApi: RestApiProvider
  ) {
    this.cargar_siniestros_nuevos();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];

    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };


   }

   updateGraph(){
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_siniestros_nuevos();
  }


  cargar_siniestros_nuevos(){
    this.restApi.cargar_siniestros_nuevos(this.subgrupo, this.parentesco).then(response => {
      this.data = response;
      this.loading = false;
    });
  }

  ngOnInit(): void {
  }

}
