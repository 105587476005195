import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-indicadores-crecimiento',
  templateUrl: './indicadores-crecimiento.component.html',
  styleUrls: ['./indicadores-crecimiento.component.css']
})
export class IndicadoresCrecimientoComponent implements OnInit {
  data:any = {};
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};

  multi = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        }
      ]
    },
  
    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        }
      ]
    },
  
    {
      "name": "France",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    }
  ];

  parentesco = [];
  subgrupo = [];

  colorScheme = {
    domain: ['#056AB0', '#A8A8A8', '#D54742', '#046B61', '#000000']
  };

  constructor(
    private restApi: RestApiProvider
  ) { 
    this.cargar_antiguedad();
    this.cargar_edad_promedio();
    this.cargar_antiguedad_promedio();
    this.cargar_crecimiento_poblacional();
    this.cargar_crecimiento_subgrupo();
    
    this.restApi.cargar_parentescos_asegurados().then(response =>{
      this.dropdownListParentesco = response['parentescos'];

    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
  }

  updateGraph(){
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_antiguedad();
    this.cargar_edad_promedio();
    this.cargar_antiguedad_promedio();
    this.cargar_crecimiento_poblacional();
    this.cargar_crecimiento_subgrupo();
    
  }

  
  cargar_crecimiento_poblacional(){
    this.restApi.cargar_crecimiento_poblacional(this.subgrupo, this.parentesco).then(response =>{
      this.data['crecimiento_poblacional'] = response['crecimiento_poblacional'];
      this.data['tasa_rotacion'] = response['tasa_rotacion'];
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }

  cargar_crecimiento_subgrupo(){
    this.restApi.cargar_crecimiento_subgrupo(this.subgrupo, this.parentesco).then(response =>{
      this.data['crecimiento_subgrupo'] = response['crecimiento_subgrupo'];
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }


  cargar_antiguedad(){
    this.restApi.cargar_antiguedad(this.subgrupo, this.parentesco).then(response =>{
      this.data['antiguedad_seguro'] = response['antiguedad_seguro'];
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }
  cargar_edad_promedio(){
    this.restApi.cargar_edad_promedio(this.subgrupo, this.parentesco).then(response =>{
      this.data['edad_promedio'] = response['edad_promedio'];
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }
  cargar_antiguedad_promedio(){
    this.restApi.cargar_antiguedad_promedio(this.subgrupo, this.parentesco).then(response =>{
      this.data['antiguedad_promedio'] = response['antiguedad_promedio'];
      this.loading = false;
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  

  onResize(event) {
    // this.view = [event.target.innerWidth / 1.35, 400];
}

  ngOnInit(): void {
  }

}
 