import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biometrics',
  templateUrl: './biometrics.component.html',
  styleUrls: ['./biometrics.component.css']
})
export class BiometricsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
