import { AjustesProvider } from 'src/app/services/ajustes';
import Swal from "sweetalert2"
import { Component, OnInit, Inject} from '@angular/core';
import { AutoRestApiProvider } from 'src/app/services/api.auto.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidadoresService } from 'src/app/shared/validators';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: []
})
export class UsuariosComponent implements OnInit {
  usuarios:any = [];
  next:string;
  previous:string;
  closeResult = '';
  constructor(
    private _rest_api: RestApiProvider,
    public dialog: MatDialog,
    private toaster: ToastrService,
    public ajustes: AjustesProvider
  ) { }

  ngOnInit() {
    this._rest_api.get_usuarios().then(response=>{
      this.usuarios = response['results']
      this.previous = response['previous'];
      this.next = response['next'];
    });
  }

  nextPage(){
    if (this.next) {
      this._rest_api.getUrl(this.next).then(response => {
        this.usuarios = response['results']
        this.previous = response['previous'];
        this.next = response['next'];
      });
    }
  }

  previousPage(){
    if (this.previous) {
      this._rest_api.getUrl(this.previous).then(response => {
        this.usuarios = response['results']
        this.previous = response['previous'];
        this.next = response['next'];
      });
    }
  }


  open(content) {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      disableClose: true,
      data: {
       data : {}
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result['data']){
        this._rest_api.crear_usuario(result['data']).
        then(response=>{
          console.log(response);
            this.toaster.success('El usuario se ha creado con exito');
            this.usuarios.push(response);
        }, error =>{
          console.error('1',error);
          this.toaster.error('Ha ocurrido un error')
        })
        .catch(err =>{
          console.error('2',err);
          this.toaster.error('Ha ocurrido un error')
        });
        
      }    
    });
  }
  
  editUser(user, index){
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      disableClose: true,
      data: {
        user:user,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result['data']){
        this._rest_api.patchUrl(user.url, result['data']).
        then(response=>{
          this.toaster.success('El usuario se ha actualizado con éxito');
          this.usuarios[index] = response;
        }, error =>{
          console.error('1',error);
          this.toaster.error('Ha ocurrido un error')
        })
        .catch(err =>{
          console.error('2',err);
          this.toaster.error('Ha ocurrido un error')
        });
        
      } 
    });
  }


  deleteuser(userUrl,index){
    Swal.fire({
      title: 'Estás seguro?',
      text: "Esta opción es irreversible, se eliminarán todas las cotizaciones y emisiones que el usuario haya generado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.value) {
        this._rest_api.deleteUrl(userUrl).then(()=>{
          this.usuarios.splice(index,1);
          this.toaster.success('Usuario eliminado con éxito');
        })
      }
    });
  }




}



export interface DialogData {
  data;
}

@Component({
  selector: 'users-add-dialog',
  templateUrl: 'usuarios.modal.component.html',
})
export class AddUserDialogComponent {
  form:FormGroup;
  user:any;
  aseguradora:any;
  aseguradoras:any;
  configuracion_actual:any = {};
  public action:string = 'Crear';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    private validators: ValidadoresService,
    public rest_api: RestApiProvider,
    public toaster: ToastrService
    ) {
      this.form = new FormGroup({
        username : new FormControl(null, [Validators.required, Validators.minLength(5), this.validators.noWhitespaceValidator], this.validators.existeUsername.bind(this) ),
        email : new FormControl(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")] , this.validators.existeEmail.bind(this)),
        password : new FormControl(null, [Validators.required] ),
        passwordConfirm : new FormControl(null, [Validators.required] ),
        first_name : new FormControl(null, [Validators.required] ),
        last_name : new FormControl(null, [Validators.required] ),
        is_active : new FormControl(null),
      });

      if(this.data && this.data['user']){
        this.user = this.data['user'];
        this.form.get('username').clearAsyncValidators();
        this.form.get('username').clearValidators();
        this.form.get('username').disable();
        console.log(this.data['user']);
        
        this.action = 'Editar';
        this.form.reset(this.data['user']);
        this.form.get('password').clearValidators();      
      }
      
    }
  cancel(){
    this.dialogRef.close();
  }

  mySortingFunction = (a, b) => {
    return a.key > b.key ? -1 : 1;
  }

  save(){
    if (this.form.invalid){
      if(this.form.get('passwordConfirm').invalid){
        if(this.form.get('password').valid){
          
          if(!this.form.get('password').value || this.form.get('password').value == ''){
            this.form.get('password').clearValidators();
            this.form.get('passwordConfirm').clearValidators();
            this.form.get('passwordConfirm').updateValueAndValidity();
            this.form.get('password').updateValueAndValidity();
            console.log('validaciones removidas');
            
            if(this.form.valid){
              this.dialogRef.close({ data: this.form.value });
            }
            else this.toaster.error('Hay errores en el formulario');
          }
        }
      }
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      })
      return;
    }
    if(this.form.valid){
      console.log(this.form);
      this.dialogRef.close({ data: this.form.value });
    }
  }


  get nameNoValido(){
    return this.form.get('first_name').invalid && this.form.get('first_name').touched;
  }
  get apellidoNoValido(){
    return this.form.get('last_name').invalid && this.form.get('last_name').touched;
  }
  get passwordNoValido(){
    return this.form.get('password').invalid && this.form.get('password').touched;
  }
  get passwordConfirmNoValido(){
    const pass1 = this.form.get('password').value;
    const pass2 = this.form.get('passwordConfirm').value;
    return (pass1 === pass2) ? false:true;
  }
  get usernameNoValido(){
    return this.form.get('username').invalid && 
    this.form.get('username').touched && 
    !this.form.get('username').hasError('existe') && 
    !this.form.get('username').hasError('whitespace');
  }

  get emailNoValido(){
    return this.form.get('email').invalid && this.form.get('email') &&
    !this.form.get('email').hasError('existe');
  }
}




