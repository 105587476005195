import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IP_CONF } from "./ip.conf";
import { AjustesProvider } from './ajustes';
import { version } from 'punycode';

@Injectable()
export class AutoRestApiProvider {
  url:string;

  constructor(    
    private http: HttpClient,
    private _ajustes: AjustesProvider
  ) {
    this.url = IP_CONF
    this._ajustes.cargarStorage();
  }

  guardar_homologacion(payload:any){
    return new Promise((resolve,reject) => {
      this.http.post(
        this.url +'guardar-homologacion/', payload,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_marcas_catalog(aseguradora){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'catalogo-marca/'+ (aseguradora).toString(),
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_marcas(){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'aba/marcas',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  getUrl(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(
        url,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_versiones(marca_id:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'aba/versiones/'+marca_id,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_versiones_catalog(aseguradora:string, marca_id:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'versiones-catalog/'+ aseguradora+ '/' +marca_id,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_modelos_catalog(aseguradora:string, version_id:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'modelos-catalog/'+ aseguradora+ '/' +version_id,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_descripciones_catalog(aseguradora:string, marca_id:number, version_id:number, modelo:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'descripciones-catalog/'+ aseguradora+ '/' + marca_id +'/' +version_id+ '/' +modelo,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_modelos(marca_id:number, version_id:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'aba/modelos/'+marca_id+'/'+version_id,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  get_descripciones(version_id:number, modelo:number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'aba/descr/'+modelo+'/'+version_id,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_cotizaciones(){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'cotizaciones-resume/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_cotizacion(id: number){
    return new Promise((resolve,reject) => {
      this.http.get(
        this.url +'cotizaciones/' + id + '/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cotizar(payload:any, aseguradora:string){
    return new Promise((resolve,reject) => {
      this.http.post(
        this.url + aseguradora +'/cotizar',
        payload,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  guardarCotizacion(payload:any){
    return new Promise((resolve,reject) => {
      this.http.post(
        this.url +'guardar-cotizacion/',
        payload,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  guardarCotizacionChild(payload:any){
    return new Promise((resolve,reject) => {
      this.http.post(
        this.url +'aseguradoras-cotizaciones/',
        payload,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          }
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  imprimirCotizacion(payload:any){
    return new Promise((resolve,reject) => {
      this.http.post(
        this.url +'crear-pdf/',
        payload,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesion.token 
          },
          responseType  : 'arraybuffer'
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
}
