import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-cedula',
  templateUrl: './cedula.component.html',
  styleUrls: ['./cedula.component.css']
})
export class CedulaComponent implements OnInit {
  data:any = {};
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';



  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];


  colorScheme = {
    domain: ['#056AB0', '#A8A8A8', '#D54742', '#046B61', '#000000']
  };
  constructor(
    private restApi:RestApiProvider
  ) {
    
    this.cargar_frecuecia_padecimientos();
    this.cargar_gasto_mensual();
    this.cargar_gasto_mensual_acumulado();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };


   }

   cargar_frecuecia_padecimientos(){
    this.restApi.cargar_cedula_padecimientos(this.subgrupo, this.parentesco).then(response => {
      this.data['padecimientos'] = response['padecimientos'];
      this.loading = false;
    });
    
   }
   cargar_gasto_mensual(){
    this.restApi.cargar_gasto_mensual(this.subgrupo, this.parentesco).then(response => {
      this.data['gasto_mensual'] = response['gasto_mensual'];
    });
   }
   cargar_gasto_mensual_acumulado(){
    this.restApi.cargar_gasto_mensual_acumulado(this.subgrupo, this.parentesco).then(response => {
      this.data['gasto_mensual_acumulado'] = response['gasto_mensual_acumulado'];
    });
   }

  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }

    this.cargar_frecuecia_padecimientos();
    this.cargar_gasto_mensual();
    this.cargar_gasto_mensual_acumulado();
  }


  ngOnInit(): void {
  }

}
