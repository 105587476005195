import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-inicio-padecimiento',
  templateUrl: './inicio-padecimiento.component.html',
  styleUrls: ['./inicio-padecimiento.component.css']
})
export class InicioPadecimientoComponent implements OnInit {
  data:any = {};
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';


  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];




  colorScheme = {
    domain: ['#0D47A1', '#B71C1C', '#42A5F5', '#FDD835', '#8D6E63', '#4DD0E1']
  };
  constructor(
    private restApi:RestApiProvider
  ) { 
    
    this.cargar_tipo_siniestro();
    this.cargar_monto_tipo_siniestro();
    this.cargar_padecimientos();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
  }

  cargar_tipo_siniestro(){
    this.restApi.cargar_tipo_siniestro(this.subgrupo, this.parentesco).then(response => {
      this.data['tipo_siniestro'] = response['tipo_siniestro'];
    });
    
  }
  cargar_monto_tipo_siniestro(){
    this.restApi.cargar_monto_tipo_siniestro(this.subgrupo, this.parentesco).then(response => {
      this.data['monto_siniestro'] = response['monto_siniestro'];
    });
    
  }
  cargar_padecimientos(){
    this.restApi.cargar_padecimientos(this.subgrupo, this.parentesco).then(response => {
      this.data['padecimientos'] = response['padecimientos'];
      this.loading = false;
    });
  }

  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_tipo_siniestro();
    this.cargar_monto_tipo_siniestro();
    this.cargar_padecimientos();
  }


  ngOnInit(): void {
  }
  

}
