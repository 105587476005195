import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AjustesProvider } from "./ajustes";
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, timeoutWith } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    constructor(
        private _router:Router,
        private _ajustes:AjustesProvider,
        private loaderService: LoaderService,
        private toaster : ToastrService

        // public toaster: ToastrService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.requests.push(request);
        // console.log("No of requests--->" + this.requests.length);
        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(request)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(request);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(request);
                        observer.error(err);
                        
                        if (err.status == 401 || err.status == 403 || err.status == 405){
                            // this.toaster.error('Error de autenticación, vuelva a iniciar sesión por favor','Error');
                            this._router.navigate(['/login']);
                            this._ajustes.borrarAjustes();
                        }
                    },
                    () => {
                        this.removeRequest(request);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(request);
                subscription.unsubscribe();
            };
        })


        // return next.handle(request).pipe(
        //     map((event: HttpEvent<any>) => {
        //         if (event instanceof HttpResponse) {
        //             // console.log('event--->>>', event);
        //         }
        //         return event;
        //     }),
        //     catchError((error: HttpErrorResponse) => {
        //         // console.log('Http error', error);
        //         if (error.status == 401 || error.status == 403 || error.status == 405){
        //             // this.toaster.error('Error de autenticación, vuelva a iniciar sesión por favor','Error');
        //             this._router.navigate(['/login']);
        //             this._ajustes.borrarAjustes();
        //         }
        //         return throwError(error);
        //     }));
    }
}



@Injectable({
    providedIn: 'root'
  })
  export class LoaderService {
   
    public isLoading = new BehaviorSubject(false);
    constructor() { }
  }