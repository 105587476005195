import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';
HighchartsMore(Highcharts);

@Component({
  selector: 'app-indicadores-edad',
  templateUrl: './indicadores-edad.component.html',
  styleUrls: ['./indicadores-edad.component.css']
})
export class IndicadoresEdadComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions:Highcharts.Options

  loading = true;
  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  dropdownListEdad = [{
    'item_text':'Mayores a 50 años',
    'item_id': 2
  },{
    'item_text':'Mayores a 64 años',
    'item_id': 3
  },{
    'item_text':'Menores a 50 años',
    'item_id': 1
  }];
  selectedItemsParentesco = [];
  selectedItemsEdad = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  edad = [];
  subgrupo = [];
  
  data:any = {}
  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  schemeType: string = 'linear';

  colorScheme = {
    domain: ['#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#103B87', '#6DC8BF', '#6DC8BF', '#6DC8BF', '#6DC8BF', '#6DC8BF', '#6DC8BF']
  };

  colorSchemeHorizontal = {
    domain: ['#103B87', '#6DC8BF', '#B72467']
  };


  legendPosition: string = 'below';


  constructor(
    private restApi : RestApiProvider
  ) { 
    
    this.restApi.cargar_parentescos_asegurados().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.cargar_indicadores_edad();

    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
  }


  updateGraph(){
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsEdad){
      this.edad = this.selectedItemsEdad.map(edd => {
        return edd.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }

    this.cargar_indicadores_edad();
    
  }

  cargar_indicadores_edad(){
    this.restApi.cargar_indicadores_edad(this.edad, this.parentesco, this.subgrupo).then(response =>{
      this.data = response;
      

      // boxplot
      this.chartOptions = {

        chart: {
          type: 'boxplot',
          renderTo:'container',
          marginLeft: 100,
          height: 1000
        },
        tooltip:{
          positioner: function(width, height, point) {
            return { x: point.plotX, y: 500}
        },
        },
      
        title: {
          text: ''
        },
      
        legend: {
          enabled: false
        },
      
        xAxis: {
          categories: this.data['edad_etiquetas'],
          
        },
      
        yAxis: {
          title: {
            text: ''
          },
          plotLines: []
        },
      
        series: [{
          name: '',
          type: undefined,
          data: [
            this.data['asegurados_categoria_edad_etiquetas']
          ],
          tooltip: {
            headerFormat: '<em>{point.key}</em>',
            pointFormatter:  function () {
              // console.log(this);
              // read values as :
              // this.y (it's the maximun value) = 965
              // this.point.low (it's the minimun value) = 760
              // this.point.median (it's the median value) = 848
              return '<br> Máximo : '+ this.high  + 
                    '<br>Cuartil superior: ' + this['q3'] + 
                    '<br>Media: ' + this['median'] +
                    '<br>Cuartil inferior: ' + this['q1'] + 
                    '<br> Mínimo: ' + this.low ;
            },
            
          }
        }, {
          name: 'Edad',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: [ // x, y positions where 0 is the first category
          ],
          marker: {
            fillColor: 'white',
            lineWidth: 1,
            lineColor: Highcharts.getOptions().colors[0]
          },
          tooltip: {
            pointFormat: '{point.y} año(s)'
          }
        }]
      }
      // boxplot
      this.chartOptions.series[0]['data'] = this.data['edad_asegurados'];
      this.chartOptions.series[1]['data'] = this.data['edad_data'];
      this.loading = false;
    });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  

  onResize(event) {
    // this.view = [event.target.innerWidth / 1.35, 400];
}



}
