import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class AjustesProvider {

  sesion = {
    'token':'',
    'user': {}
  }

  constructor() {
  }

  cargarStorage(){
    let promesa = new Promise((resolve, reject)=>{
      if (localStorage.getItem('sesion')){
      this.sesion = JSON.parse(localStorage.getItem('sesion'))
      resolve(true);
      }
    });
    return promesa    
  }

  guardarStorage(){
    localStorage.setItem('sesion', JSON.stringify(this.sesion))
  }

  borrarAjustes(){
    this.sesion.token = null;
    this.sesion.user = {};
    this.guardarStorage();
  }
}
