import { RestApiProvider } from 'src/app/services/request.services';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  password:string;
  confirmPassword:string;
  code:string;

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private restApi: RestApiProvider,
    private activeParams: ActivatedRoute
  ) { 
    this.activeParams.params.subscribe(params => {
      if (params && params['code']){
        this.code = params['code'];
      }
    });
  }

  resetPass() {
    if (!this.password  && this.password != '') {
      this.toaster.error('Ingrese una contraseña');
      return;
    }
    if (this.password !== this.confirmPassword) {
      this.toaster.error('Las contraseñas no coinciden');
      return;
    }
    this.restApi.reset_password(this.password, this.code).then(response => {
      this.toaster.success('Contraseña actualizada exitosamente');
      this.router.navigate(['/login']);
    }, error => {
      this.toaster.error('Ocurrió un error con la solicitud, inténtelo nuevamente');
    })
    .catch(err => {
      this.toaster.error('Ocurrió un error con la solicitud, inténtelo nuevamente');
    });
  }

  ngOnInit(): void {
  }

}
