import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.css']
})
export class HospitalComponent implements OnInit {
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  data:any = {}
  view: any[] = [700, 5000];
  view2: any[] = [700, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Population';


  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];



  colorScheme = {
    domain: ['#0D47A1', '#B71C1C', '#42A5F5', '#FDD835', '#8D6E63', '#4DD0E1']
  };

  constructor(
    private restApi:RestApiProvider
  ) { 
    
    this.cargar_uso_hospitalario();
    this.cargar_hospitales();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

    
  }
  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_uso_hospitalario();
    this.cargar_hospitales();
  }

  cargar_uso_hospitalario(){
    this.restApi.cargar_uso_hospitalario(this.subgrupo, this.parentesco).then(response =>{
      this.data['uso_hospitalario'] = response['uso_hospitalario'];
      this.cargar_padecimimentos_atendidos(this.data['uso_hospitalario'][0]['name'])
      this.loading = false;
    });
  }

  cargar_hospitales(){
    this.restApi.cargar_hospitales(this.subgrupo, this.parentesco).then(response =>{
      this.data['hospitales'] = response['hospitales'];
    });
    
  }

  cargar_padecimimentos_atendidos(hospital){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }


    this.restApi.cargar_padecimientos_atendidos(hospital, this.subgrupo, this.parentesco).then(response =>{
      this.data['padecimientos_atendidos'] = response['padecimientos_atendidos'];
      if (this.loading)this.loading = false;
    });
  }

  ngOnInit(): void {
  }
  onSelect(data): void {
    this.cargar_padecimimentos_atendidos(data['name'])
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }

}
