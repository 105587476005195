import { IP_CONF } from './../services/ip.conf';
import Swal from "sweetalert2"
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiProvider } from '../services/request.services';
import { AjustesProvider } from '../services/ajustes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username:string;
  password: string;

  constructor(
    public router: Router,
    private _restApi: RestApiProvider,
    private _ajustes: AjustesProvider,
    private toaster: ToastrService
  ) {
   }

  ngOnInit() {
  }

  ingresar(){
    if (!this.username) { 
      this.toaster.error('Ingresa un nombre de usuario o un email válido');
      return;
    }
    if (!this.password) { 
      this.toaster.error('Ingresa una contraseña válida');
      return;
    }
    this._restApi.login(this.username, this.password).then(response => {
      if(response['token']){
        this._ajustes.sesion.token = response['token'];
        this._ajustes.sesion.user['name'] = response['name'];
        this._ajustes.sesion.user['email']= response['email'];
        this._ajustes.sesion.user['rol']= response['rol'];
        this._ajustes.guardarStorage();
        this.router.navigate(['/dashboard']); 
      }
    }, error => {
      this.toaster.error('No se puede iniciar sesión con estas credenciales ó el usuario está desactivado');
    })
    .catch(err => {
      this.toaster.error('No se puede iniciar sesión con estas credenciales ó el usuario está desactivado');
    });
  }


  forgot_password(){
    Swal.fire({
      title: 'Ingrese su correo electrónico',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Restablecer contraseña',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        if(login == ''){
          Swal.showValidationMessage(
            "Error: Ingrese un email válido"
          )
          return
        }
        return fetch(`${IP_CONF}forgot-password/${login}`)
          .then(response => {
            console.log(response);
            
            if (response.status == 404) {
              throw new Error("El email ingresado no existe, asegurese de haber escrito correctamente y que el usuario no este desactivado por algún administrador.")
            }
            return response.status
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result);
      if (result.value == 200) {
        Swal.fire({
          title: "Correo de restablecimiento de contraseña enviado, revise su bandeja de entrada para continuar con el proceso."
        })
      }
    })
    
  }
}
