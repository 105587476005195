import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IP_CONF } from "../services/ip.conf";
import { AjustesProvider } from './ajustes';

@Injectable()
export class RestApiProvider {

  constructor(    
    private http: HttpClient,
    private ajustes: AjustesProvider
  ) {
    this.ajustes.cargarStorage();
  }

  login(username:string, password:string){
    console.log('IP',IP_CONF)
    return new Promise((resolve,reject) => {
      this.http.post(
        IP_CONF+'api-token-auth/', 
        {'username':username, 'password':password},
        {headers: { 'Content-Type': 'application/json' }}        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  getUrl(url:string) {
    return new Promise((resolve,reject) => {
      this.http.get(url, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        },
        withCredentials: true
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  checkToken() {
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'checkToken', {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        },
        withCredentials: true
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  deleteUrl(url:string){
    return new Promise((resolve,reject) => {
      this.http.delete(url,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  patchUrl(url:string,data:any){
    return new Promise((resolve,reject) => {
      this.http.patch(url,data,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  set_init_date(date: Date){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF + 'fecha-inicio-poliza', {date}, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  get_folio(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'folio', {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  get_folio_asegurados(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'file-name-asegurados', {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  save_data(item: any){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF + 'data', {item}, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  set_end_date(date: Date){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF + 'fecha-fin-poliza', {date}, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_init_date(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'fecha-inicio-poliza', {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_end_date(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'fecha-fin-poliza', {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  saveErrorLog(error:any){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF +'errores/', error, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  crear_usuario(usuario:any){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF +'usuarios/', usuario, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_indicadores_poblacionales(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'indicadores-poblacionales/indicadores-poblacionales',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  get_altas_bajas(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'altas-y-bajas/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  get_prima_devolucion(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'prima-devolucion/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  piramide_poblacional(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'indicadores-poblacionales/piramide-poblacional',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  mujeres_en_edad_fertil(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'indicadores-poblacionales/mujeres-edad-fertil',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  distribucion_por_sexo(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'indicadores-poblacionales/distribucion-sexo',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_indicadores_edad(edad, parentesco, subgrupo){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'indicadores-edad',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            edad,
            parentesco,
            subgrupo
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  cargar_momentos_vida_edad(subgrupo, genero){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'momentos-vida-edad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            genero
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_momentos_vida_edo_civil(subgrupo, genero){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'momentos-vida-estado-civil',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            genero
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_grafica_distribucion_titulares(subgrupo, genero){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'grafica-distribucion-titulares',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            genero
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_momentos_vida_dist_edad(subgrupo, genero){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'momentos-vida-distribucion-edad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            genero
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  
  cargar_siniestros_por_estado(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'siniestros-por-estado',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_indicadores_siniestralidad(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/indicadores-siniestralidad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  cargar_siniestros_por_mes(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/siniestros-pagados-por-mes',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  cargar_parentescos_asegurados(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'parentescos-asegurados',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  cargar_parentescos_siniestralidad(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'parentescos-siniestralidad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  cargar_grupos(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'grupos',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  cargar_generos_siniestralidad(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'generos-siniestralidad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }




  cargar_siniestros_nuevos(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'gasto/siniestros-nuevos',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  cargar_severidad(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'severidad',
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  cargar_serveridad_padecimientos(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'cedula/padecimientos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  reset_password(password: any, code:string){
    return new Promise((resolve,reject) => {
      this.http.post(
        IP_CONF +'reset-password/',
        {password, code},
        {
          headers: { 
            'Content-Type': 'application/json'
          }
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  get_usuarios(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'usuarios/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
  // frecuencia
  
  cargar_frecuecia_por_padecimiento(subgrupo, parentesco, sexo){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'frecuencia/frecuencia-por-padecimiento',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco,
            sexo
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_frecuecia_padecimientos(subgrupo, parentesco, sexo){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'frecuencia/padecimientos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco,
            sexo
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_cedula_padecimientos(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'cedula/padecimientos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  

  cargar_gasto_mensual(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'cedula/gasto-mensual',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 
  

  cargar_gasto_mensual_acumulado(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'cedula/gasto-acumulado',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_tipo_de_pago(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'tipo-tramite/tipo-de-pago',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  
  cargar_proveedor(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'tipo-tramite/proveedor',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  

  cargar_tipo_siniestro(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'inicio-padecimiento/tipo-siniestro',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  

  cargar_monto_tipo_siniestro(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'inicio-padecimiento/monto-siniestro',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  

  cargar_padecimientos(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'inicio-padecimiento/padecimientos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_uso_hospitalario(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/uso-hospitalario',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_medicos(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/medicos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_hospitales(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/hospitales',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_generales(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/generales',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 
  
  cargar_lista_padecimientos(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/lista-padecimientos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_padecimientos_atendidos(hospital:string,  subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/padecimientos-atendidos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params: {
            hospital,
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_medico_padecimientos_atendidos(hospital:string, subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/medico-padecimientos-atendidos',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params: {
            hospital,
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_conteo_siniestros(padecimiento:string){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'hospitales/conteo-siniestros',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params: {
            'padecimiento':padecimiento
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_antiguedad(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/antiguedad-seguro',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  guardar_admin(payload:any){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF + 'config', payload,
      {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        },
        withCredentials: true
      }         
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  obtener_admin(){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'config',
      {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this.ajustes.sesion.token ,
          
        },
        withCredentials: true
      }         
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_crecimiento_por_subgrupo(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/crecimiento-poblacion-subgrupo',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_crecimiento_poblacional(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/crecimiento-poblacional',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  cargar_crecimiento_subgrupo(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/crecimiento-por-subgrupo',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_antiguedad_promedio(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/antiguedad-promedio',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 


  cargar_edad_promedio(subgrupo, parentesco){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'principales-indicadores/edad-promedio',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          params:{
            subgrupo,
            parentesco
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  
 
  existe_email(email:any, id:number){
    return new Promise((resolve,reject) => {
      this.http.post(IP_CONF + 'existe-email/',{"email":email, 'id':id},
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  existe_username(username:any){
    return new Promise((resolve,reject) => {
      this.http.get(IP_CONF + 'existe-username/'+username,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this.ajustes.sesion.token ,
            
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  exist_profile_name(control:any, profile){
    return new Promise(()=>{});
  }
  

  
}
