import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-frecuencia',
  templateUrl: './frecuencia.component.html',
  styleUrls: ['./frecuencia.component.css']
})
export class FrecuenciaComponent implements OnInit {
  @Input() trimXAxisTicks: boolean = true;
  @Input() trimYAxisTicks: boolean = true;
  @Input() maxXAxisTickLength: number = 16;
  @Input() maxYAxisTickLength: number = 16;
  data:any = {}
 

  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  dropdownListSexo = [];
  selectedItemsParentesco = [];
  selectedItemsSexo = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];
  sexo = [];

  
  view: any[] = [700, 5000];
  view2: any[] = [700, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Population';

  colorScheme = {
    domain: ['#0D47A1', '#B71C1C', '#42A5F5', '#FDD835', '#8D6E63', '#4DD0E1']
  };

  constructor(
    private restApi: RestApiProvider,
    private router:Router
  ) {
    

    this.cargar_frecuecia_padecimientos();
    this.cargar_frecuecia_por_padecimiento();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    });
    
    this.restApi.cargar_generos_siniestralidad().then(response =>{
      this.dropdownListSexo = response['genero'];
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };
    
  }

  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    
    if(this.selectedItemsSexo){
      this.sexo = this.selectedItemsSexo.map(sub => {
        return sub.item_id;
      })
    }
    this.cargar_frecuecia_padecimientos();
    this.cargar_frecuecia_por_padecimiento();
  }

  cargar_frecuecia_padecimientos(){
    this.restApi.cargar_frecuecia_por_padecimiento(this.subgrupo, this.parentesco, this.sexo).then(response => {
      this.data['frecuencia_por_padecimiento'] = response['frecuencia_por_padecimiento'];
    }, error => {this.loading = false;}).catch(err=>{this.loading = false;});
    
  }
  cargar_frecuecia_por_padecimiento(){
    this.restApi.cargar_frecuecia_padecimientos(this.subgrupo, this.parentesco, this.sexo).then(response => {
      this.data['padecimientos'] = response['padecimientos'];
      this.loading = false;
    }, error => {this.loading = false;}).catch(err=>{this.loading = false;});
  }

  onSelect(data): void {
    let pad = JSON.parse(JSON.stringify(data))['label']
    this.router.navigate(['/analisis-proveedor'],{queryParams:{'padecimiento':pad}})
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }

  ngOnInit(): void {
  }

}
