import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages.routes';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { AddUserDialogComponent } from "./usuarios/usuarios.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { IndicadoresPoblacionalesComponent } from './indicadores-poblacionales/indicadores-poblacionales.component';
import { IndicadoresCrecimientoComponent } from './indicadores-crecimiento/indicadores-crecimiento.component';
import { IndicadoresEdadComponent } from './indicadores-edad/indicadores-edad.component';
import { MomentosVidaComponent } from './momentos-vida/momentos-vida.component';
import { IndicadoresSiniestralidadComponent } from './indicadores-siniestralidad/indicadores-siniestralidad.component';
import { SeveridadComponent } from './severidad/severidad.component';
import { CedulaComponent } from './cedula/cedula.component';
import { GastoComponent } from './gasto/gasto.component';
import { BiometricsComponent } from './biometrics/biometrics.component';
import { FrecuenciaComponent } from './frecuencia/frecuencia.component';
import { InicioPadecimientoComponent } from './inicio-padecimiento/inicio-padecimiento.component';
import { TipoTramiteComponent } from './tipo-tramite/tipo-tramite.component';
import { HospitalComponent } from './hospital/hospital.component';
import { MedicoComponent } from './medico/medico.component';
import { SiniestralidadPorEstadoComponent } from './siniestralidad-por-estado/siniestralidad-por-estado.component';
import { DetaleAltasBajasComponent } from './detale-altas-bajas/detale-altas-bajas.component';
import { AnalisisProveedorComponent } from './analisis-proveedor/analisis-proveedor.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CargaArchivosComponent } from './carga-archivos/carga-archivos.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AltasBajasComponent } from './altas-bajas/altas-bajas.component';
import { AdminComponent } from './admin/admin.component';




@NgModule({
  declarations: [
    DashboardComponent,
    PagesComponent,
    UsuariosComponent,
    AddUserDialogComponent,
    IndicadoresPoblacionalesComponent,
    IndicadoresCrecimientoComponent,
    IndicadoresEdadComponent,
    MomentosVidaComponent,
    IndicadoresSiniestralidadComponent,
    SeveridadComponent,
    CedulaComponent,
    GastoComponent,
    BiometricsComponent,
    FrecuenciaComponent,
    InicioPadecimientoComponent,
    TipoTramiteComponent,
    HospitalComponent,
    MedicoComponent,
    SiniestralidadPorEstadoComponent,
    DetaleAltasBajasComponent,
    AnalisisProveedorComponent,
    CargaArchivosComponent,
    AltasBajasComponent,
    AdminComponent
  ],
  exports:[
    DashboardComponent,
    PagesComponent
  ],
  imports:[
      SharedModule,
      PagesRoutingModule,
      CommonModule,
      BrowserModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatTabsModule,
      MatSlideToggleModule,
      NgxChartsModule,
      HighchartsChartModule,
      NgMultiSelectDropDownModule.forRoot(),
      FileUploadModule
  
      
  ],
  entryComponents: [
    AddUserDialogComponent,
  ]
})
export class PagesModule { }
