import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RestApiProvider } from 'src/app/services/request.services';

@Component({
  selector: 'app-indicadores-siniestralidad',
  templateUrl: './indicadores-siniestralidad.component.html',
  styleUrls: ['./indicadores-siniestralidad.component.css']
})
export class IndicadoresSiniestralidadComponent implements OnInit {
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  data:any = {}
  
  multi: any[];
  subgrupos_iniciales = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_neta = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_devengada = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_sniestralidad = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_siniestros = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_frecuencia = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }
  subgrupos_prima_siniestro_promedio = {
    'TECHINT': 0,
    "TENARIS": 0,
    "TECPETROL":0
  }


  loading = true;

  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};
  parentesco = [];
  subgrupo = [];

  expuestos = 0;
  prima_devengada = 0;
  siniestralidad = 0;
  casos_al_corte = 0;
  frecuencia = 0;
  siniestro_promedio = 0;
  prima_neta = 0;
  iniciales = 0;

  colorScheme1 = {
    domain: ['#81DCE4']
  };
  
  colorScheme = {
    domain: ['#81DCE4', '#056AB0', '#0D47A1', '#FDD835', '#8D6E63', '#4DD0E1']
  };

  constructor(
    private restApi: RestApiProvider
  ) { 

    this.restApi.obtener_admin().then(response => {
      this.subgrupos_iniciales['TECHINT'] = parseFloat(response['techint']['iniciales']);
      this.subgrupos_iniciales['TENARIS'] = parseFloat(response['tenaris']['iniciales']);
      this.subgrupos_iniciales['TECPETROL'] = parseFloat(response['tecpetrol']['iniciales']);

      this.subgrupos_prima_neta['TECHINT'] = parseFloat(response['techint']['prima_neta']);
      this.subgrupos_prima_neta['TENARIS'] = parseFloat(response['tenaris']['prima_neta']);
      this.subgrupos_prima_neta['TECPETROL'] = parseFloat(response['tecpetrol']['prima_neta']);

      this.subgrupos_prima_devengada['TECHINT'] = parseFloat(response['techint']['prima_devengada']);
      this.subgrupos_prima_devengada['TENARIS'] = parseFloat(response['tenaris']['prima_devengada']);
      this.subgrupos_prima_devengada['TECPETROL'] = parseFloat(response['tecpetrol']['prima_devengada']);

      this.subgrupos_prima_sniestralidad['TECHINT'] = parseFloat(response['techint']['prima_siniestralidad']);
      this.subgrupos_prima_sniestralidad['TENARIS'] = parseFloat(response['tenaris']['prima_siniestralidad']);
      this.subgrupos_prima_sniestralidad['TECPETROL'] = parseFloat(response['tecpetrol']['prima_siniestralidad']);

      this.subgrupos_prima_siniestros['TECHINT'] = parseFloat(response['techint']['numero_siniestros']);
      this.subgrupos_prima_siniestros['TENARIS'] = parseFloat(response['tenaris']['numero_siniestros']);
      this.subgrupos_prima_siniestros['TECPETROL'] = parseFloat(response['tecpetrol']['numero_siniestros']);

      this.subgrupos_prima_frecuencia['TECHINT'] = parseFloat(response['techint']['frecuencia']);
      this.subgrupos_prima_frecuencia['TENARIS'] = parseFloat(response['tenaris']['frecuencia']);
      this.subgrupos_prima_frecuencia['TECPETROL'] = parseFloat(response['tecpetrol']['frecuencia']);

      this.subgrupos_prima_siniestro_promedio['TECHINT'] = parseFloat(response['techint']['siniestro_promedio']);
      this.subgrupos_prima_siniestro_promedio['TENARIS'] = parseFloat(response['tenaris']['siniestro_promedio']);
      this.subgrupos_prima_siniestro_promedio['TECPETROL'] = parseFloat(response['tecpetrol']['siniestro_promedio']);
    })
    
    this.cargar_indicadores_siniestralidad();
    this.cargar_siniestros_por_mes();
    this.cargar_hospitales();
    this.cargar_generales();

    this.restApi.cargar_parentescos_siniestralidad().then(response =>{
      this.dropdownListParentesco = response['parentescos'];
    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];

      this.expuestos = 0;
      this.prima_devengada = 0;
      this.siniestralidad = 0;
      this.frecuencia = 0;
      this.siniestro_promedio = 0;
      this.prima_neta = 0;
      this.iniciales = 0;
      

      this.dropdownListSubgrupo.forEach(item => {
        this.expuestos += this.subgrupos_prima_siniestros[item["item_id"]];
        this.prima_devengada += this.subgrupos_prima_devengada[item["item_id"]];
        this.siniestralidad += this.subgrupos_prima_sniestralidad[item["item_id"]];
        this.frecuencia += this.subgrupos_prima_frecuencia[item["item_id"]];
        this.siniestro_promedio += this.subgrupos_prima_siniestro_promedio[item["item_id"]];
        this.prima_neta += this.subgrupos_prima_neta[item["item_id"]];
        this.iniciales += this.subgrupos_iniciales[item["item_id"]];
      });
      this.frecuencia = (this.expuestos / this.iniciales) * 100;
      this.siniestro_promedio = (this.siniestralidad / this.expuestos);
    }); 

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

    
    
  }

  updatePrimas(){
    console.log(this.selectedItemsSubgrupo);
    if(this.selectedItemsSubgrupo.length == 0){
      this.expuestos = 0;
      this.prima_devengada = 0;
      this.siniestralidad = 0;
      this.frecuencia = 0;
      this.siniestro_promedio = 0;
      this.prima_neta = 0;
      this.iniciales = 0;
      

      this.dropdownListSubgrupo.forEach(item => {
        this.expuestos += this.subgrupos_prima_siniestros[item["item_id"]];
        this.prima_devengada += this.subgrupos_prima_devengada[item["item_id"]];
        this.siniestralidad += this.subgrupos_prima_sniestralidad[item["item_id"]];
        this.prima_neta += this.subgrupos_prima_neta[item["item_id"]];
        this.iniciales += this.subgrupos_iniciales[item["item_id"]];
      });
    } else {
      this.expuestos = 0;
      this.prima_devengada = 0;
      this.siniestralidad = 0;
      this.frecuencia = 0;
      this.siniestro_promedio = 0;
      this.prima_neta = 0;
      this.iniciales = 0;

      this.selectedItemsSubgrupo.forEach(item => {
        this.expuestos += this.subgrupos_prima_siniestros[item["item_id"]];
        this.prima_devengada += this.subgrupos_prima_devengada[item["item_id"]];
        this.siniestralidad += this.subgrupos_prima_sniestralidad[item["item_id"]];
        this.frecuencia += this.subgrupos_prima_frecuencia[item["item_id"]];
        this.prima_neta += this.subgrupos_prima_neta[item["item_id"]];
        this.iniciales += this.subgrupos_iniciales[item["item_id"]];
      });
    }

      this.frecuencia = (this.expuestos / this.iniciales) * 100;
      this.siniestro_promedio = (this.siniestralidad / this.expuestos);
  }

  cargar_indicadores_siniestralidad(){
    this.restApi.cargar_indicadores_siniestralidad(this.subgrupo, this.parentesco).then(response =>{
      this.data['tipo_padecimiento_reclamacion'] = response['tipo_padecimiento_reclamacion'];
      this.data['tipo_padecimiento_siniestro'] = response['tipo_padecimiento_siniestro'];
      this.data['tipo_pago'] = response['tipo_pago'];
      this.loading = false;
    });
    
  }
  cargar_siniestros_por_mes(){
    this.restApi.cargar_siniestros_por_mes(this.subgrupo, this.parentesco).then(response =>{
      this.data['siniestros_pagados_por_mes'] = response['siniestros_pagados_por_mes'];
    });
    
  }
  cargar_hospitales(){
    this.restApi.cargar_hospitales(this.subgrupo, this.parentesco).then(response =>{
      this.data['hospitales'] = response['hospitales'];
    });
  }

  cargar_generales(){
    this.restApi.cargar_generales(this.subgrupo, this.parentesco).then(response =>{
      this.data['generales'] = response['generales'];
    });
  }


  updateGraph(){
    this.parentesco = [];
    this.subgrupo = [];
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }

    this.cargar_indicadores_siniestralidad();
    this.cargar_siniestros_por_mes();
    this.cargar_hospitales();
    this.cargar_generales();
  }

  ngOnInit(): void {

  }

}
