import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiProvider } from 'src/app/services/request.services';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private _rest_api:RestApiProvider,
    public router: Router
  ) { 
  }

  ngOnInit() {
  }

  irPagina(parametro){
    this.router.navigate(['/'+parametro]);
  }

}
