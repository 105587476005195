import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/interceptor';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RestApiProvider } from 'src/app/services/request.services';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  loading: boolean;
  fin: NgbDateStruct;
  inicio: NgbDateStruct;
  date: {year: number, month: number};

  constructor(
    private loaderService: LoaderService,
    private calendar: NgbCalendar,
    private toastr: ToastrService,
    private restApi: RestApiProvider
  ) {
   }

  ngOnInit() {
    this.restApi.get_init_date().then(response =>{
      this.inicio = response['data'];
    }, error => {
      console.log(error);
    }).catch( error =>{
      console.log(error);
    })
    this.restApi.get_end_date().then(response =>{
      this.fin = response['data'];
    }, error => {
      console.log(error);
    }).catch( error =>{
      console.log(error);
    })
  }


  
  enterDateInit(){
    this.restApi.set_init_date(new Date(this.inicio['year'],this.inicio['month'] -1, this.inicio['day'])).then(response => {
      this.toastr.success('Fecha de inicio de póliza actualizada', 'Fecha cambiada')
    }, error => {
      try {
        this.toastr.error(error['error'], 'Error')
      } catch (e){
        this.toastr.error('Ocurrió un problema al cambiar la fecha inténtelo nuevamente', 'Error')
      }
    }).catch( error =>{
      try {
        this.toastr.error(error['error'], 'Error')
      } catch (e){
        this.toastr.error('Ocurrió un problema al cambiar la fecha inténtelo nuevamente', 'Error')
      }
    })
    
  }

  enterDateEnd(){
    this.restApi.set_end_date(new Date(this.fin['year'],this.fin['month'] -1, this.fin['day'])).then(response => {
      this.toastr.success('Fecha de inicio de póliza actualizada', 'Fecha cambiada')
    }, error => {
      try {
        this.toastr.error(error['error'], 'Error')
      } catch (e){
        this.toastr.error('Ocurrió un problema al cambiar la fecha inténtelo nuevamente', 'Error')
      }
    }).catch( error =>{
      try {
        this.toastr.error(error['error'], 'Error')
      } catch (e){
        this.toastr.error('Ocurrió un problema al cambiar la fecha inténtelo nuevamente', 'Error')
      }
    })
    
  }

}
