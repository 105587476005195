import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AjustesProvider } from 'src/app/services/ajustes';
import { RestApiProvider } from 'src/app/services/request.services';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as Highcharts from 'highcharts';


// import * as Exporting from 'highcharts/modules/exporting';
// Initialize exporting module.
// Exporting(Highcharts);

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-indicadores-poblacionales',
  templateUrl: './indicadores-poblacionales.component.html',
  styleUrls: ['./indicadores-poblacionales.component.css']
})
export class IndicadoresPoblacionalesComponent implements OnInit {
  data:any = {};
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions:Highcharts.Options;
  loading = true;
  dropdownListParentesco = [];
  dropdownListSubgrupo = [];
  selectedItemsParentesco = [];
  selectedItemsSubgrupo = [];
  dropdownSettings:IDropdownSettings = {};

  parentesco = [];
  subgrupo = [];
  
  colorScheme = {
    domain: ['#056AB0', '#A8A8A8', '#D54742', '#046B61', '#000000']
  };
  
  colorSchemeBars = {
    domain: ['#0D47A1', '#B71C1C', '#42A5F5', '#FDD835', '#8D6E63', '#4DD0E1']
  };
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  view: any[] = [];

  // 
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegendBars: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = '';
  legendPositionBars: string = 'below';

  schemeType: string = 'linear';
  colorSchemeBarsHorizontal = {
    domain: ['#F7A8F0', '#33D1FF']
  };
  showLegendBarsHorizontal: boolean = false;
  categories = [
    "0-4",
    "5-9",
    "10-14",
    "15-19",
    "20-24",
    "25-29",
    "30-34",
    "35-39",
    "40-44",
    "45-49",
    "50-54",
    "55-59",
    "60-64",
    "65-69",
    "70-74",
    "75-79",
    "80-84"
  ];
  
  constructor(
    private restApi: RestApiProvider,
    private ajustes: AjustesProvider,
    private http: HttpClient
  ) {
    this.view = [innerWidth / 1.3, 400];
   

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

    this.restApi.cargar_parentescos_asegurados().then(response =>{
      this.dropdownListParentesco = response['parentescos'];

    }); 

    this.restApi.cargar_grupos().then(response =>{
      this.dropdownListSubgrupo = response['grupos'];
    }); 

    this.piramide_poblacional();

    this.mujeres_en_edad_fertil();

    this.distribucion_por_sexo();

    this.cargar_indicadores_poblacionales();

    this.cargar_crecmiento_por_subgrupo();
  }

  cargar_crecmiento_por_subgrupo(){
    this.restApi.cargar_crecimiento_por_subgrupo(this.subgrupo, this.parentesco).then(response =>{
      this.data['crecimiento_subgrupo'] = response['crecimiento_subgrupo'];
    }, error => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }



  updateGraph(){
    this.loading = true;
    if(this.selectedItemsParentesco){
      this.parentesco = this.selectedItemsParentesco.map(paren => {
        return paren.item_id;
      })
    }

    if(this.selectedItemsSubgrupo){
      this.subgrupo = this.selectedItemsSubgrupo.map(sub => {
        return sub.item_id;
      })
    }
    this.piramide_poblacional();

    this.mujeres_en_edad_fertil();

    this.distribucion_por_sexo();

    this.cargar_indicadores_poblacionales();

    this.cargar_crecmiento_por_subgrupo();
    
    
  }

  mujeres_en_edad_fertil(){
    this.restApi.mujeres_en_edad_fertil(this.subgrupo, this.parentesco).then(response =>{
      this.data['mujeres_edad_fertil'] = response['mujeres_edad_fertil'];
      this.data['mujeres_total'] = response['mujeres_total'];
      this.data['porcentaje_mujeres_edad_fertil'] = response['porcentaje_mujeres_edad_fertil'];
    }); 
  }

  distribucion_por_sexo(){
    this.restApi.distribucion_por_sexo(this.subgrupo, this.parentesco).then(response =>{
      this.data['distribucion_sexo'] = response['distribucion_sexo'];
    }); 
  }

  cargar_indicadores_poblacionales(){
    this.restApi.cargar_indicadores_poblacionales(this.subgrupo, this.parentesco).then(response =>{
      this.data['asegurados'] = response['asegurados'];
      this.data['titulares'] = response['titulares'];
      this.data['edad_promedio'] = response['edad_promedio'];
      this.data['asegurados_mayores_50_anios'] = response['asegurados_mayores_50_anios'];
      this.data['asegurados_mayores_64_anios'] = response['asegurados_mayores_64_anios'];
      this.data['hijos_mayores_25_anios'] = response['hijos_mayores_25_anios'];
      this.data['tasa_dependientes_por_titular'] = response['tasa_dependientes_por_titular'];
      this.loading = false;
      
      
    }); 
  }

  piramide_poblacional(){
    let aux_lista_hombres = [];
    let aux_lista_mujeres = [];
    this.restApi.piramide_poblacional(this.subgrupo, this.parentesco).then(response =>{
      this.data['lista_hombres'] = response['lista_hombres'];
      this.data['lista_mujeres'] = response['lista_mujeres'];
      this.data['piramide_poblacional'] = response['piramide_poblacional'];
      this.categories.forEach(edad => {
        aux_lista_hombres.push(parseInt(this.data['lista_hombres'][edad])*-1);
        aux_lista_mujeres.push(this.data['lista_mujeres'][edad]);
      });
      this.chartOptions = {
          chart: {
              renderTo:'container',
              marginLeft: 100,
              type: 'bar'
              //  plotAreaWidth: 50,
              //   plotAreaHeight: 450,
          },
      
          title: {
              text: ''
          },
      
          yAxis: {
            title: {
                text: null
            },
            labels: {
                formatter: function () {
                    return '';
                }
            }
        },
      
          xAxis: [{
            categories: this.categories,
            reversed: false,
            labels: {
                step: 1
            }
        }, { // mirror axis on right side
            opposite: true,
            reversed: false,
            categories: this.categories,
            linkedTo: 0,
            labels: {
                step: 1
            }
        }],
      
          legend: {
              enabled: false
          },
      
          series: [{
            name: 'Hombres',
            dataLabels: {
                enabled: false,
                format: '{y}'
            },
            color:'skyblue',
            type: undefined,
            data: [
                -2.2, -2.1, -2.2, -2.4,
                -2.7, -3.0, -3.3, -3.2,
                -2.9, -3.5, -4.4, -4.1,
                -13.4, -2.7, -2.3, -2.2,
                -1.6
            ]
        }, {
            name: 'Mujeres',
            color:'pink',
            type: undefined,
            data: [
                2.1, 2.0, 2.1, 2.3, 2.6,
                2.9, 3.2, 3.1, 2.9, 3.4,
                14.3, 4.0, 3.5, 2.9, 2.5,
                2.7, 2.2
            ]
        }]
  
      }
      
      this.chartOptions.series[0]['data'] = this.chartOptions.series[0]['data'].map((item,index) => {
        return aux_lista_hombres[index];
      });

      this.chartOptions.series[1]['data'] = this.chartOptions.series[0]['data'].map((item,index) => {
        return aux_lista_mujeres[index];
      });
    }); 
  }


  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  

  onResize(event) {
    // this.view = [event.target.innerWidth / 1.35, 400];
}

  ngOnInit(): void {
  }

}
