import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AjustesProvider } from 'src/app/services/ajustes';
import { RestApiProvider } from 'src/app/services/request.services';
import { FileUploadService } from '../carga-archivos/file-upload.service.ts';
import * as XLSX from 'xlsx';


@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public techint:any = {
    'iniciales':"",
    'prima_neta':"",
    'prima_devengada':"",
    'prima_siniestralidad':"",
    'numero_siniestros':"",
    'frecuencia':"",
    'siniestro_promedio':""
  };
  public tenaris:any = {
    'iniciales':"",
    'prima_neta':"",
    'prima_devengada':"",
    'prima_siniestralidad':"",
    'numero_siniestros':"",
    'frecuencia':"",
    'siniestro_promedio':""
  };
  public tecpetrol:any = {
    'iniciales':"",
    'prima_neta':"",
    'prima_devengada':"",
    'prima_siniestralidad':"",
    'numero_siniestros':"",
    'frecuencia':"",
    'siniestro_promedio':""
  };


  constructor(
    private rest_api : RestApiProvider,
    private ajustes: AjustesProvider,
    private http: HttpClient,
    private uploadService: FileUploadService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.rest_api.obtener_admin().then(response => {
      this.toastr.success('Info cargada');
      this.techint = response['techint'];
      this.tenaris = response['tenaris'];
      this.tecpetrol = response['tecpetrol'];
    })
  }

  save(){
    let payload = {
      'techint': this.techint,
      'tenaris': this.tenaris,
      'tecpetrol': this.tecpetrol
    }
    this.rest_api.guardar_admin(payload).then(response => {
      this.toastr.success('Info guardada');
    }) 
  }


  

}
