import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
import { AjustesProvider } from 'src/app/services/ajustes';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  
  menu:any = [
    {
      descr : 'Dashboard',
      url : '/dashboard',
      icono: '../../../assets/images/menu/principal.svg',
    },
    {
      descr : 'Crecimiento',
      url : 'indicadores-crecimiento',
      icono: '../../../assets/images/menu/Indicadores_crecimiento_blanco.svg',
    },
    {
      descr : 'Edad',
      url : 'indicadores-edad',
      icono: '../../../assets/images/menu/Indicadores_edad_blanco.svg',
    },
    {
      descr : 'Poblacionales',
      url : 'indicadores-poblacionales',
      icono: '../../../assets/images/menu/Indicadores_poblacionales_blanco.svg',
    },
    {
      descr : 'Momentos de vida',
      url : 'momentos-vida',
      icono: '../../../assets/images/menu/momentos_vida_blanco.svg',
    },
    {
      descr : 'Principales indicadores siniestralidad',
      url : 'principales-indicadores-siniestralidad',
      icono: '../../../assets/images/menu/Indicadores_siniestrales_blanco.svg',
    },
    {
      descr : 'Severidad',
      url : 'severidad',
      icono: '../../../assets/images/menu/severidad_blanco.svg',
    },
    {
      descr : 'Cédula',
      url : 'cedula',
      icono: '../../../assets/images/menu/cedula_blanco.svg',
    },
    {
      descr : 'Siniestralidad por estado',
      url : 'siniestralidad-estado',
      icono: '../../../assets/images/menu/siniestralidad_estado_blanco.svg',
    },
    {
      descr : 'Médico',
      url : 'medico',
      icono: '../../../assets/images/menu/medico_blanco.svg',
    },
    {
      descr : 'Análisis proveedor',
      url : 'analisis-proveedor',
      icono: '../../../assets/images/menu/analisis_proveedor_blanco.svg',
    },
    // {
    //   descr : 'Biometrics',
    //   url : 'biometrics',
    //   icono: 'fa fa-folder',
    // },
    {
      descr : 'Hospital',
      url : 'hospital',
      icono: '../../../assets/images/menu/hospital_blanco.svg',
    },
    {
      descr : 'Frecuencia',
      url : 'frecuencia',
      icono: '../../../assets/images/menu/frecuencia_blanco.svg',
    },
    {
      descr : 'Inicio de padecimiento',
      url : 'inicio-padecimiento',
      icono: '../../../assets/images/menu/Inicio_padecimiento_blanco.svg',
    },
    {
      descr : 'Tipo de trámite',
      url : 'tipo-tramite',
      icono: '../../../assets/images/menu/tipo_tramite_blanco.svg',
    },
    {
      descr : 'Gasto',
      url : 'gasto',
      icono: '../../../assets/images/menu/gasto_blanco.svg',
    },
    // {
    //   descr : 'Detalle altas/bajas',
    //   url : 'detalle-altas-bajas',
    //   icono: 'fa fa-folder',
    // },
    // {
    //   descr : 'Análisis proveedor',
    //   url : 'analisis-proveedor',
    //   icono: '../../../assets/images/menu/analisis_proveedor_blanco.svg',
    // },
    {
      descr : 'Altas y bajas',
      url : '/altas-bajas',
      icono: '../../../assets/images/menu/carga_archivos_blanco.svg',
    },{
      descr : 'Carga de archivos',
      url : '/carga-archivos',
      icono: '../../../assets/images/menu/carga_archivos_blanco.svg',
    },{
      descr : 'Administrador',
      url : '/admin',
      icono: '../../../assets/images/menu/carga_archivos_blanco.svg',
    },
  ]
  
  constructor(
    public _sidebarService : SidebarService,
    private _ajustes: AjustesProvider,
    private _router:Router
  ) {  
   }

  ngOnInit() {
    
  }

  salir(){
    this._ajustes.borrarAjustes();
    this._router.navigate(['/login']); 
  }


}
