import { Component, OnInit } from '@angular/core';
import { DataShareService } from "../data.share.service";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnInit {
  page_title:string = '';
  constructor(
    private dataShare : DataShareService
  ) {
    this.dataShare.currentTitle.subscribe(message => {
      this.page_title = message;
    });

    
   }

  ngOnInit() {
  }

}
