import { Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { FileItem, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { IP_CONF } from "../../services/ip.conf";
import * as XLSX from 'xlsx';
import { forkJoin, Observable, Subject } from 'rxjs';
import { RestApiProvider } from 'src/app/services/request.services';
import { AjustesProvider } from 'src/app/services/ajustes';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { FileUploadService } from './file-upload.service.ts';
import { ToastrService } from 'ngx-toastr';


const URL = IP_CONF + 'data'

const input = document.getElementById('input')


@Component({
  selector: 'app-carga-archivos',
  templateUrl: './carga-archivos.component.html',
  styleUrls: ['./carga-archivos.component.css']
})
export class CargaArchivosComponent implements OnInit {
  
  selectedFiles?: FileList;
  selectedFilesAsegurados?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  messageAsegurados = '';
  file_name;
  file_name_asegurados;
  loading:boolean = false;

  fileInfos?: Observable<any>;
  
  constructor(
    private rest_api : RestApiProvider,
    private ajustes: AjustesProvider,
    private http: HttpClient,
    private uploadService: FileUploadService,
    private toastr: ToastrService
  ) { 
    this.ajustes.cargarStorage();
    this.rest_api.get_folio().then(response =>{
      this.file_name = response;
    })
    this.rest_api.get_folio_asegurados().then(response =>{
      this.file_name_asegurados = response;
    })
    
    
  }


  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  selectFileAsegurados(event: any): void {
    this.selectedFilesAsegurados = event.target.files;
  }

  upload(): void {
    this.message = undefined;
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;
        this.loading = true;
        this.uploadService.upload(this.currentFile).subscribe(
          (event: any) => {
            this.loading = false;
            this.toastr.success('Archivo cargado correctamente','Archivo cargado');
            this.fileInfos = this.uploadService.getFiles();
          },
          (err: any) => {
            this.currentFile = undefined;
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.messageAsegurados = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
            }
            this.currentFile = undefined;
          });
      }
      this.selectedFiles = undefined;
    }
  }


  uploadAsegurados(): void {
    this.messageAsegurados = undefined;
    
    this.progress = 0;

    if (this.selectedFilesAsegurados) {
      const file: File | null = this.selectedFilesAsegurados.item(0);

      if (file) {
        this.currentFile = file;
        this.loading = true;
        this.uploadService.uploadAsegurados(this.currentFile).subscribe(
          (event: any) => {
            this.toastr.success('Archivo cargado correctamente','Archivo cargado');
            this.fileInfos = this.uploadService.getFilesAsegurados();
            this.loading = false;
          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.messageAsegurados = err.error.message;
              this.selectedFilesAsegurados = undefined;
            } else {
              this.messageAsegurados = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
              this.selectedFilesAsegurados = undefined;
            }

            this.currentFile = undefined;
          });

      }

      this.selectedFilesAsegurados = undefined;
    }
  }



  
  ngOnInit(): void {
  }
}

