import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AjustesProvider } from 'src/app/services/ajustes';
import { RestApiProvider } from 'src/app/services/request.services';
import { FileUploadService } from '../carga-archivos/file-upload.service.ts';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-altas-bajas',
  templateUrl: './altas-bajas.component.html',
  styleUrls: ['./altas-bajas.component.css']
})
export class AltasBajasComponent implements OnInit {

  selectedFilesAltas?: FileList;
  selectedFilesBajas?: FileList;
  currentFileAltas?: File;
  currentFileBajas?: File;
  progress = 0;
  message = '';
  file_name;
  readyToUpload = false;
  readyToUploadBajas = false;
  data:any = {};
  next:string;
  previous:string;
  erroresAltas:any = [];
  erroresBajas:any = [];
  dataString:any;
  dataStringBajas:any;
  loadingAltas = false;
  loadingBajas = false;
  

  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;

  fileInfos?: Observable<any>;
  constructor(
    private rest_api : RestApiProvider,
    private ajustes: AjustesProvider,
    private http: HttpClient,
    private uploadService: FileUploadService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.rest_api.get_altas_bajas().then(response =>{
      this.data['altas_bajas'] = response
    });
    
    this.rest_api.get_prima_devolucion().then(response =>{
      this.data['devolucion'] = response['devolucion'];
      this.data['deuda'] = response['deuda'];
      this.data['total'] = response['total'];
    });


  }

  nextPage(){
    this.rest_api.getUrl(this.next).then(response =>{
      this.data['altas_bajas'] = response['results'];
      this.next = response['next'];
      this.previous = response['previous'];
    })
  }
  
  previousPage(){
    this.rest_api.getUrl(this.previous).then(response =>{
      this.data['altas_bajas'] = response['results'];
      this.next = response['next'];
      this.previous = response['previous'];
    })
  }

  selectFileAltas(event: any): void {
    this.selectedFilesAltas = event.target.files;
    
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      this.dataString = jsonData;
    }
    reader.readAsBinaryString(file);
  }

  CheckuploadAltas(): void {
    if (this.selectedFilesAltas) {
      const file: File | null = this.selectedFilesAltas.item(0);

      if (file) {
        this.currentFileAltas = file;
        this.loadingAltas = true;
        this.uploadService.checkAltasBajas(this.currentFileAltas, 'altas').subscribe(
          (event: any) => {
            this.loadingAltas = false;
            this.erroresAltas = event.body.errores;            
            if(event.body.errores.length == 0){
              this.toastr.success('Archivo validado correctamente','Archivo validado');
              this.readyToUpload = true;
            } else {
              this.selectedFilesAltas = undefined;
              this.toastr.error('Ocurrieron errores al validar el archivo','Archivo validado');
            }
          },
          (err: any) => {
            this.loadingAltas = false;
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
            }

            this.currentFileAltas = undefined;
          });

      }
    }
  }
  
  uploadAltas(): void {
    this.progress = 0;

    if (this.selectedFilesAltas) {
      const file: File | null = this.selectedFilesAltas.item(0);

      if (file) {
        this.currentFileAltas = file;
        this.loadingAltas = true;
        this.uploadService.uploadAltasBajas(this.currentFileAltas, 'altas').subscribe(
          (event: any) => {
            this.loadingAltas = false;
            this.erroresAltas = event.body.errores;    
            if(event.body.errores.length == 0){
              this.toastr.success('Archivo cargado correctamente','Archivo cargado');
              this.readyToUpload = false;
              setTimeout(() => {
                location.reload();
              }, 1500);
            } else {
              this.toastr.success('Ocurrió un error al cargar los archivos','Error');
            }
          },
          (err: any) => {
            this.loadingAltas = false;
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
            }

            this.currentFileAltas = undefined;
          });

      }

    }
  }

  selectFileBajas(event: any): void {
    this.selectedFilesBajas = event.target.files;
    
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      this.dataStringBajas = jsonData;
    }
    reader.readAsBinaryString(file);
  }

  CheckuploadBajas(): void {
    if (this.selectedFilesBajas) {
      const file: File | null = this.selectedFilesBajas.item(0);
      if (file) {
        this.currentFileBajas = file;
        this.loadingBajas = true;
        this.uploadService.checkAltasBajas(this.currentFileBajas, 'bajas').subscribe(
          (event: any) => {
            this.loadingBajas = false;
            this.erroresBajas = event.body.errores;            
            if(event.body.errores.length == 0){
              this.toastr.success('Archivo validado correctamente','Archivo validado');
              this.readyToUploadBajas = true;
            } else {
              this.selectedFilesBajas = undefined;
              this.toastr.error('Ocurrieron errores al validar el archivo','Archivo validado');
            }
          },
          (err: any) => {
            this.loadingBajas = false;
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
            }

            this.currentFileBajas = undefined;
          });

      }
    }
  }
  
  uploadBajas(): void {
    this.progress = 0;

    if (this.selectedFilesBajas) {
      const file: File | null = this.selectedFilesBajas.item(0);

      if (file) {
        this.currentFileBajas = file;
        this.loadingBajas = true;
        this.uploadService.uploadAltasBajas(this.currentFileBajas, 'bajas').subscribe(
          (event: any) => {
            this.loadingBajas = false;
            this.erroresBajas = event.body.errores;    
            if(event.body.errores.length == 0){
              this.toastr.success('Archivo cargado correctamente','Archivo cargado');
              this.readyToUpload = false;
              setTimeout(() => {
                location.reload();
              }, 1500);
            } else {
              this.toastr.success('Ocurrió un error al cargar los archivos','Error');
            }
          },
          (err: any) => {
            this.loadingBajas = false;
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'No se puede subir el archivo asegurese de escoger el archivo correcto!';
            }

            this.currentFileBajas = undefined;
          });

      }

    }
  }

  

}
