import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IP_CONF } from "../../services/ip.conf";
import { AjustesProvider } from 'src/app/services/ajustes';

const URL = IP_CONF

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private http: HttpClient,
    private ajustes:AjustesProvider
    ) { }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    const headers= new HttpHeaders()
    .set('Authorization', 'Token '+ this.ajustes.sesion.token);

    const req = new HttpRequest('POST', `${URL}data/`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers : headers
      
    });

    return this.http.request(req);
  }

  uploadAltasBajas(file: File, tipo:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('tipo', tipo);
    const headers= new HttpHeaders()
    .set('Authorization', 'Token '+ this.ajustes.sesion.token);

    const req = new HttpRequest('POST', `${URL}altas-bajas-data/`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers : headers
      
    });

    return this.http.request(req);
  }
  
  checkAltasBajas(file: File, tipo:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('tipo', tipo);
    const headers= new HttpHeaders()
    .set('Authorization', 'Token '+ this.ajustes.sesion.token);

    const req = new HttpRequest('POST', `${URL}check-altas-bajas-data/`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers : headers
      
    });

    return this.http.request(req);
  }

  uploadAsegurados(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    const headers= new HttpHeaders()
    .set('Authorization', 'Token '+ this.ajustes.sesion.token);

    const req = new HttpRequest('POST', `${URL}asegurados-data/`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers : headers
      
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${URL}/folio`);
  }getFilesAsegurados(): Observable<any> {
    return this.http.get(`${URL}/file-name-asegurados`);
  }
}