import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataShareService {

  private titleSource = new BehaviorSubject('');
  currentTitle = this.titleSource.asObservable();

  private data = new BehaviorSubject({});

  constructor() { }

  changeTitle(message: string) {
    this.titleSource.next(message)
  }

  sendData(payload: any) {
    this.data.next(payload);
  }

}